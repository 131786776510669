<template>
	<v-sheet class="ma-2">
		<v-textarea filled rounded :value="value" @input="v => $emit('input', v)" :ref="reference"></v-textarea>

		<condition-button
			v-for="(button, i) in operators"
			:key="i"
			v-bind="button"
			@click="append"
			color="primary"
		></condition-button>

		<v-divider class="mb-12 mt-12"></v-divider>


        
		<question-condition
			v-for="question in questions"
			:key="question"
			:id="question"
			@click="appendQuestion"
			:focus="questionFocus"
		></question-condition>

        <condition-answer-options v-if="questionFocus" :id="questionFocus.question" @exit="questionFocus = null" @input="append">
        </condition-answer-options>


	</v-sheet>
</template>

<script>
import ConditionButton from "@c/survey/builder/conditions/ConditionButton";
import QuestionCondition from "@c/survey/builder/conditions/QuestionCondition";
import ConditionAnswerOptions from "@c/survey/builder/conditions/ConditionAnswerOptions";
export default {
	name: "EditConditions",
	props: {
		id: {type: String, default: "1" },
		value: { type: String, default: " " },
		questions: { type: Array },
	},
	data: () => {
		return {
			position: 0,
			questionFocus: null,
		};
	},
	computed: {
		reference(){
			return `${this.id}-conditionString`
		},
		operators() {
			return [
				{ value: "(" },
				{ value: ")" },
				{ label: "AND", value: "&&" },
				{ label: "OR", value: "||" },
				{ label: "=", value: "==" },
				{ label: "NOT", value: "!==" },
				{ label: ">", value: ">" },
				{ label: "<", value: "<" },
			];
		},
	},
	components: {
		ConditionButton,
		QuestionCondition,
        ConditionAnswerOptions
	},
	methods: {
		
		append(wot) {
			let el = this.$refs[this.reference].$el.querySelector('textarea');
			let n = el.selectionStart;
			let value = this.value || "";
			if( !n ){
				n = value.length;
			}
			let first = value.substr(0, n );
			let second = value.substr( n );
			value = `${first}${wot}${second}`
			this.$emit("input", value );
		},
		appendQuestion(which) {
			this.questionFocus = which;
			this.append(which.string);
		},
	},
};
</script>
