<template>
	<v-sheet color="transparent" flat class="mt-2">
		<v-checkbox v-model="question.always_hidden" label="Always Hidden"></v-checkbox>
		<edit-condition-string v-model="string" :questions="previousQuestions" :id="id"></edit-condition-string>
		<p class="text-right">
			<v-btn color="success" @click="save">Save</v-btn>
		</p>
	</v-sheet>
</template>

<style lang="less" scoped>
.v-chip {
	cursor: pointer;
}
</style>

<script>

import EditConditionString from "@c/survey/builder/conditions/EditConditionString";

export default {
	name: "EditVisibilityRules",
	props: {
		context: { type: String },
		id: { type: String }, // @doc the array of values
	},
	data: () => {
		return {
			string: "",
		};
	},
	computed: {
		question() {
			return this.$store.state.questions.data[this.id];
		},
		previousQuestions() {
			return this.$store.getters["surveys/getPreviousQuestions"](this.context, this.id )

		},
	},
	watch: {
		id: {
			immediate: true, 
			handler(){
				this.makeString();
			}
		}
	},
	methods: {
		makeString(){
			this.string = this.question.visible_if;
		},
		save() {
			this.$store.dispatch("questions/patch", {
				id: this.id,
				visible_if: this.string,
				always_hidden: this.question.always_hidden
			});
			this.$root.$emit('showToast', 'Saved');
		},
	},
	components: {
		EditConditionString
	}
};
</script>
