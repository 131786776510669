<template>
	<v-sheet flat>
		<v-divider class="mb-8 mt-12"></v-divider>
			<v-text-field v-if="!hasOptions" v-model="answer" :type="type">
			</v-text-field>
			<template v-else>
				<condition-button
					v-for="(option, id) in question.answer_options"
					:key="id"
					:label="option.text"
					:color="answer == option.value ? 'success' : 'primary'"
					:value="option.value"
					@click="answer = option.value"
				></condition-button>
			</template>
		<v-card-actions>
			<v-btn text @click="$emit('exit')">Cancel</v-btn>
			<v-spacer></v-spacer>
			<v-btn large icon @click="submit" color="success">
				<v-icon>mdi-check-circle</v-icon>
			</v-btn>
		</v-card-actions>
	</v-sheet>
</template>

<script>
import ConditionButton from "@c/survey/builder/conditions/ConditionButton.vue";

export default {
	components: { ConditionButton },
	name: "ConditionAnswerOptions",
	props: {
		id: { type: String },
	},
	data: () => {
		return {
			answer: null,
		};
	},
	computed: {
		question() {
			return this.$store.state.questions.data[this.id];
		},
		type() {
			return this.question.input_type;
		},
		hasOptions() {
			let answer_options = this.question.answer_options;
			answer_options = Object.values(answer_options);
			return answer_options.length > 0;
		},
	},
	methods: {
		cancel() {
			this.answer = null;
			this.$emit("exit");
		},
		submit() {
            let answer = this.answer;
            if( this.type !== "number" ){
                answer = `'${answer}'`;
            }
			this.$emit("input", answer );
			this.cancel();
		},
	},
};
</script>
