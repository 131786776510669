<template>
	<v-sheet flat>
		<p class="title">Options: {{ title }}</p>
		<list-options
			:id="id"
			:context="context"
			v-model="question.answer_options"
			@save="save"
			@delete="deleteOption"
		></list-options>
		<v-btn color="primary" @click="save">
			Save Options
		</v-btn>
	</v-sheet>
</template>

<script>
import ListOptions from "@c/survey/builder/options/ListOptions";

export default {
	name: "EditOptions",
	props: {
		id: { type: String },
		context: { type: String },
	},
	data: () => {
		return {
			tab: 0,
			options: [],
			show_more: [],
		};
	},
	computed: {
		title() {
			return this.$store.getters["questions/title"](this.id);
		},
		question() {
			return this.$store.state.questions.data[this.id];
		},
	},
	methods: {
		deleteOption(id) {
			this.$store.dispatch(
				"questions/delete",
				`${this.id}.answer_options.${id}`
			)
		},
		save() {
			const self = this;
			self.$store.dispatch("questions/patch", {
				id: self.id,
				answer_options: self.question.answer_options,
			}).then( () => {
				self.$root.$emit("showToast", "Saved");
			})
		},
	},

	components: {
		ListOptions,
	},
};
</script>
