

<template>
	<mw-dialog v-model="dialog" fullscreen>
		<template v-slot:button>
			<v-btn icon @click="dialog = true"
				><v-icon>mdi-magnify</v-icon></v-btn
			>
		</template>
		<template v-slot:dialog-body>
			<survey-inner v-if="dialog" :id="value" @save="dialog = false"></survey-inner>
		</template>
	</mw-dialog>
</template>
<script>
import MwDialog from "@c/ui/MwDialog";
export default {
	name: "Preview",
	components: {
        MwDialog,
		SurveyInner: () => import("@c/survey/render/Survey"),
    },
	props: {
		value: { type: String }, // the id of the survey
	},
    data: () =>{
        return {
            dialog: false
        }
    }
}
</script>