<template>
	<v-sheet flat>
		<v-tabs right v-model="tab">
			<v-tab>default</v-tab>
			<v-tab>paste</v-tab>
		</v-tabs>
		<v-tabs-items class="pt-3" v-model="tab">
			<v-tab-item>
				<v-expansion-panels flat v-model="show_more" multiple>
					<mw-option
						v-for="(option, i) in options"
						:key="i"
						@click="toggleMore(i)"
						:question="id"
						:context="context"
						v-bind="option"
						:active="show_more.includes(i)"
						@update="(k, v) => (option[k] = v)"
						@move="move"
						@delete="v => deleteOption(v, i)"
					>
					</mw-option>
				</v-expansion-panels>
				<v-row class="mt-2">
					<v-col>
						<v-btn color="primary" text @click="addOption">
							Add Option
						</v-btn>
					</v-col>
				</v-row>
			</v-tab-item>
			<v-tab-item>
				<paste-options v-model="options"></paste-options>
			</v-tab-item>
		</v-tabs-items>
	</v-sheet>
</template>

<script>
import PasteOptions from "@c/survey/builder/options/PasteOptions";
import MwOption from "@c/survey/builder/options/MwOption";

export default {
	name: "EditOptions",
	props: {
		id: { type: String },
		context: { type: String },
		value: { type: [Object, Array] },
	},
	data: () => {
		return {
			tab: 0,
			initial_items: {},
			options: [],
			show_more: [],
		};
	},
	computed: {
		title() {
			return this.$store.getters["questions/title"](this.id);
		},
		question() {
			return this.$store.state.questions.data[this.id];
		},

	},
	methods: {
		makeOptions() {
			let options = this.value;
			if (!options) {
				return [];
			}
			this.options = this.mwutils.sortByKey(
				Object.values(options),
				"position"
			);
		}, 
		addOption() {
			this.options.push({
				text: "",
				value: "0",
				position: this.options.length - 1,
				visible_if: "",
			});
		},
		move(id, direction) {
			var self = this;
			var order = self.options.map((a) => a.value);
			self.mwutils.moveItemInArray(id, order, direction);
			var neworder = order.map((b) => {
				return self.options.find((c) => c.value == b);
			});
			neworder.map((o, i) => {
				o.position = i;
				return o;
			});
			self.options = neworder;
		},
		toggleMore(i) {
			if (this.show_more.includes(i)) {
				this.show_more = this.show_more.filter((a) => a !== i);
			} else {
				this.show_more.push(i);
			}
		},
		deleteOption(value, i){
			this.$emit("delete", value )
			this.options.splice(i, 1);
		}
	},
	watch: {
		options: {
			deep: true,
			handler(nv) {
				const self = this;
				nv = nv.filter( n => n.value );
				let options = Object.fromEntries(
					nv.map((o) => [o.value, o])
				);
				if( self.value ){
					let oldValues = Object.keys(self.value)
					oldValues.forEach( o => {
						if( !options[o] ){
							self.$emit("delete", o )
						}
					})
				}
				this.$emit("input", options);
			},
		},
		id: {
			immediate: true,
			handler() {
				this.makeOptions();
			},
		},
	},
	components: {
		PasteOptions,
		MwOption,
	},
};
</script>
