<template>
	<mw-dialog v-model="dialog" :title="title" fullscreen>
		<template v-slot:button>
			<v-btn icon @click="dialog = true"
				><v-icon>mdi-plus-circle</v-icon></v-btn
			>
		</template>
		<template v-slot:dialog-body>
			<v-autocomplete
				v-if="targetCollection == 'consents'"
				:items="projects"
				v-model="project"
				label="Project"
			></v-autocomplete>
			<v-autocomplete
				:items="users"
				v-model="participant"
				label="Participant"
			></v-autocomplete>
			<!-- <mw-date-picker v-model="created_at"></mw-date-picker> -->
			<survey-inner v-if="dialog" :id="value" @save="submit"></survey-inner>
		</template>
	</mw-dialog>
</template>
<script>
import MwDialog from "@c/ui/MwDialog";
// import MwDatePicker from "@c/ui/MwDatePicker";
export default {
	name: "InsertResponse",
	components: {
        MwDialog,
		// MwDatePicker,
		SurveyInner: () => import("@c/survey/render/Survey"),
    },
	props: {
		value: { type: String }, // the id of the survey
	},
	data: () => {
		return {
			dialog: false,
			participant: null,
			// created_at: null,
			project: null
		};
	},
	computed: {
		participants(){
			if( !this.project ){
				return [];
			}
			return this.$store.getters["appointmentParticipants/byProject"](this.project)
		},
		users(){
			const self = this;
			let users = self.$store.getters.getByKey("users", "role", "participant", true );
			if( self.targetCollection == "consents" ){
				return self.participants.map( p => {
					if( users[p.user] ){
						return {value: p.id, text: users[p.user].name }
					}
				}).filter( u => u );
			}
			users = Object.values( users );
			return users.map( u => ({value: u.id, text: u.name })).filter( u => u );
		},
		projects(){
			let projects = this.$store.getters.getByKey("projects", "consent", this.value );
			return projects.map( u => ({value: u.id, text: u.internal_name }))
		},
		survey() {
			return this.$store.state.surveys.data[this.value];
		},
		title() {
			return this.survey
				? this.survey.internal_name + ": Adding Response"
				: "";
		},
		targetCollection(){
			if( this.survey.type == "consent-form" ){
				return "consents"
			}
			return "surveyResponses";
		}
	},
	watch:{
		project(v){
			if(v){
				this.$store.dispatch("appointments/fetchByProject", v )
			}
		}
	},
	methods: {
		submit(doc) {
			// doc.created_at = this.created_at;
			if( this.targetCollection == "consents" ){
				doc.appointment_participant = this.participant,
				doc.project = this.project;
				doc.status = "published";
			}
			else{
				doc.status = "submitted";
				doc.participant = this.participant;
			}
			this.$store.dispatch(`${this.targetCollection}/insert`, doc ).then( () => {
				this.dialog = false;
			})
        },
	},
	created() {
		this.$store.dispatch("users/fetchByKey", {key: "role", value: "participant"})
		this.$store.dispatch("projects/fetchByKey", {key: "consent", value: this.value })
	}
};
</script>
