<template>
	<v-expansion-panel class="mb-1">
		<v-card flat outlined>
			<v-card-title>
				<v-spacer></v-spacer>
				<v-btn x-small icon @click="$emit('move', value, 'up')">
					<v-icon>mdi-arrow-up</v-icon>
				</v-btn>
				<v-btn x-small icon @click="$emit('move', value, 'down')">
					<v-icon>mdi-arrow-down</v-icon>
				</v-btn>
				<v-btn x-small icon @click="$emit('delete', value )">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col class="mt-0">
						<span>
							{{ text }}
							<v-icon class="ml-1" v-if="visible_if" color="success">mdi-star</v-icon>
						</span>
						<v-btn
							small
							icon
							@click="$emit('click')"
							:color="active ? 'success' : ''"
						>
							<v-icon>
								{{ active ? "mdi-chevron-down" : "mdi-chevron-right" }}
							</v-icon>
						</v-btn>
					</v-col>
				</v-row>
				<v-expansion-panel-content>
					<v-text-field
						:value="value"
						@input="v => update('value', v)"
						label="Value"
						hint="This should be a short string with no special characters. This is for database purposes and isn't displayed to the participant or client"
					></v-text-field>
					<v-text-field
						@input="v => update('text', v )"
						:value="text"
						label="Label"
					></v-text-field>
					<edit-condition-string
						:value="visible_if"
						@input="v => update('visible_if', v )"
						:questions="previousQuestions"
						:id="value"
					></edit-condition-string>
				</v-expansion-panel-content>
			</v-card-text>
		</v-card>
	</v-expansion-panel>
</template>
<style lang="less">
.v-expansion-panel-content__wrap {
	padding: 0;
	margin: 0;
}
</style>
<script>
import EditConditionString from "@c/survey/builder/conditions/EditConditionString";
export default {
	name: "MwOption",
	props: {
		active: { type: Boolean },
		text: { type: String },
		value: { type: String },
		visible_if: { type: String, default: "" },
		context: { type: String },
		question: { type: String },
		position: { type: [String, Number] },
	},
	data: () => {
		return {};
	},
	computed: {
		previousQuestions() {
			return this.$store.getters["surveys/getPreviousQuestions"](
				this.context,
				this.question
			);
		},
	},
	methods: {
		update(k, v){
			this.$emit('update', k, v);
		}
	},
	components: {
		EditConditionString,
	},
};
</script>
