<template>
	<v-card v-if="question" class="mb-3" flat outlined :color="active ? 'lightest': 'default'">
		<v-card-title class="cursor-pointer" @click="$emit('sidebar', 'question')">
			<v-btn small icon id="handle">
				<v-icon>mdi-drag</v-icon>
			</v-btn>

			<v-spacer></v-spacer>
			<v-btn small icon @click="$emit('copyId')">
				<v-icon>mdi-numeric</v-icon>
			</v-btn>
			<v-btn small icon @click="$emit('deleteQuestion')">
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<v-btn small icon @click="$emit('copyQuestion')">
				<v-icon small>mdi-content-copy</v-icon>
			</v-btn>
			<v-btn small icon @click="$emit('move', 'up')">
				<v-icon>mdi-arrow-up</v-icon></v-btn>
			<v-btn small icon @click="$emit('move', 'down')">
				<v-icon>mdi-arrow-down</v-icon>
			</v-btn>
		</v-card-title>
		<v-card-text  class="cursor-pointer" @click="$emit('sidebar', 'question')">
		
					<p class="large-light" v-html="mwsurveyutils.renderQuestionContent(question.id)"></p>
					<template  v-if="question.subquestions">
						<span>{{mwsurveyutils.renderSubquestionContent(question.id)}}</span><br/>
					</template>
					<template  v-if="question.content">
						<div v-html="question.content"></div>
					</template>
					<i v-if="question.answer_options">{{mwsurveyutils.renderAnswerOptionContent(question.id)}}</i>

		</v-card-text>
		<v-card-actions>

			<v-btn text small :color="haveConditions ? 'success' : 'secondary' " @click="$emit('sidebar', 'visibility' )">
				Manage Visibility
				<v-icon v-if="haveConditions" color="success" class="ml-1">mdi-star</v-icon>
			</v-btn>
			<v-btn v-if="haveOptions" text small color="secondary' " @click="$emit('sidebar', 'options')">
				Edit Options
			</v-btn>
			<v-btn v-if="haveSubquestions" text small color="secondary' " @click="$emit('sidebar', 'subquestions')">
				Edit Subquestions 
			</v-btn>
			<v-icon color="error" v-if="question.mandatory">mdi-asterisk</v-icon>
			<v-spacer></v-spacer>
			<v-chip color="primary">{{question.type}}</v-chip>
		</v-card-actions>
	</v-card>
</template>

<style lang="less">
	.large-light {
		font-size: 1.5em;
		font-weight: 300;
	}
	.cursor-pointer{
		cursor: pointer;
	}
</style>

<script type="text/javascript">
import Question from "@/models/Question";

export default {
	name: "EditQuestion",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
		context: { type: Array },
		active: {type: Boolean, default: false }
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	components: {
	},
	computed: {
		question() {
			return this.$store.state.questions.data[this.id];
		},
		model() {
			return Question;
		},
		haveConditions(){
			return this.question.always_hidden ||( this.question.visible_if && this.question.visible_if.length > 0);
		},
		haveOptions(){
			return ["checkboxes", "tiles", "matrix"].includes(this.question.type)
		},
		haveSubquestions(){
			return ["matrix"].includes(this.question.type)
		}
	},
	methods: {
		update(value, key) {
			this.$store.dispatch("questions/patch", { id: this.id, [key]: value });
		},
	},
	created() {
		this.$store.dispatch("questions/fetchById", this.id);
	},
};
</script>
