<template>
	<v-sheet flat>
		<v-sheet v-if="survey">
			<v-toolbar flat>
				<p class="large-light">{{ survey.title }}</p>
				<v-spacer></v-spacer>
				<insert-response :value="id"></insert-response>
				<preview :value="id"></preview>
				<v-btn icon @click="showSidebar('survey')"
					><v-icon>mdi-cog</v-icon></v-btn
				>
				<v-btn icon router-link :to="{name: 'SurveyResponses', params: {by: 'survey', id: this.id } }"><v-icon>mdi-table-arrow-right</v-icon></v-btn>
			</v-toolbar>
			<v-sheet flat>
				<draggable v-model='survey.questions' @end="save" handle="#handle">
					<edit-question
						v-for="question in survey.questions"
						:id="question"
						:key="question"
						:active="question_focus == question"
						@deleteQuestion="deleteQuestion(question)"
						@copyId="copyId(question)"
						@copyQuestion="copyQuestion(question)"
						@move="(direction) => moveQuestion(question, direction)"
						
						@sidebar="v => showSidebar(v, question )"
					></edit-question>
				</draggable>
				<p class="mt-3 mb-3">
					<v-btn @click="newQuestion" color="primary" text class="mr-3"
						>New Question</v-btn
					>
					<v-btn @click="openLibrary" color="primary" text
						>Question Library</v-btn
					>
				</p>
			</v-sheet>
			<v-navigation-drawer right v-model="sidebar" app width="400">
				<v-toolbar flat color="transparent">
					<v-spacer></v-spacer>
					<v-btn icon @click="sidebar = false"
						><v-icon>mdi-close</v-icon></v-btn
					>
				</v-toolbar>
				<v-card class="ma-2" flat color="transparent">
					<template v-if="sidebar_content == 'visibility'">
						<edit-visibility :id="question_focus" :context="id">
						</edit-visibility>
					</template>
					<template v-if="sidebar_content == 'options'">
						<edit-options :id="question_focus" :context="id">
						</edit-options>
					</template>
					<template v-if="sidebar_content == 'subquestions'">
						<edit-subquestions :id="question_focus" :context="id">
						</edit-subquestions>
					</template>
					<template v-if="sidebar_content == 'survey'">
						<edit-survey-settings :id="id">
							<screener-settings :id="id" v-if="survey.type == 'screener'"></screener-settings>
						</edit-survey-settings>
					</template>
					<template v-if="sidebar_content == 'library'">
						<question-library :context="id"></question-library>
					</template>
					<template v-if="sidebar_content == 'question'">
						<edit-question-settings
							:id="question_focus"
						></edit-question-settings>
					</template>
				</v-card>
			</v-navigation-drawer>
		</v-sheet>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">

import draggable from 'vuedraggable'

import EditSurveySettings from "@c/survey/builder/EditSurveySettings";
import EditQuestionSettings from "@c/survey/builder/EditQuestionSettings";
import QuestionLibrary from "@c/survey/builder/QuestionLibrary";
import EditQuestion from "@c/survey/builder/EditQuestion";
import EditVisibility from "@/components/survey/builder/conditions/EditVisibility";
import EditOptions from "@/components/survey/builder/EditOptions";
import EditSubquestions from "@/components/survey/builder/EditSubquestions";
import ScreenerSettings from '@c/survey/builder/ScreenerSettings.vue';
import InsertResponse from '@c/survey/InsertResponse.vue';
import Preview from '@c/survey/Preview.vue';

export default {
	name: "SurveyBuilder",
	props: {
		id: { type: String, required: true }, // @doc the id string of the object - will add a new document if not passed
	},
	data: () => {
		return {
			sidebar: false,
			sidebar_content: null,
			question_focus: false,
		};
	},
	computed: {
		survey() {
			return this.$store.state.surveys.data[this.id];
		},
		title(){
			return this.survey ? this.survey.internal_name : ""
		}
	},
	components: {
		draggable,
		EditVisibility,
		EditSurveySettings,
		EditQuestion,
		QuestionLibrary,
		EditQuestionSettings,
		EditOptions, 
		EditSubquestions, 
		ScreenerSettings,
		InsertResponse,
		Preview
	},
	methods: {
		setTitle(v) {
			this.$store.commit("SET_TITLE", v  );
		},
		copyId(id){
			const self = this;
			navigator.clipboard.writeText(id).then( () => {
				self.$root.$emit("showToast", "Copied")
			})
		},
		openLibrary() {
			this.sidebar_content = "library";
			this.sidebar = true;
		},
		newQuestion() {
			this.$store.dispatch("questions/insert", {}).then((ref) => {
				this.$store.dispatch("surveys/addQuestion", {
					id: this.id,
					question: ref,
				});
			});
		},
		deleteQuestion(id) {
			this.$store.dispatch("surveys/removeQuestion", {
				id: this.id,
				question: id,
			});
		},
		copyQuestion(id) {
			let question = this.$store.state.questions.data[id];
			let newQuestion = { ...question };
			delete newQuestion.id;
			this.$store.dispatch("questions/insert", newQuestion).then((ref) => {
				this.$store.dispatch("surveys/addQuestion", {
					id: this.id,
					question: ref,
				});
			});
		},
		moveQuestion(id, direction) {
			var values = [...this.survey.questions];
			this.mwutils.moveItemInArray(id, values, direction);
			this.$store.dispatch("surveys/patch", {
				id: this.id,
				questions: values,
			});
		},
		showSidebar(type, id) {
			this.sidebar_content = type;
			if (id) {
				this.question_focus = id;
			}
			this.sidebar = true;
		},
		save(){
			this.$store.dispatch("surveys/patch", {
				id: this.id,
				questions: this.survey.questions,
			});

		}
	},
	watch: {
		title: {
			immediate: true, 
			handler(v){
				if( v ){

					this.setTitle(v);
				}
			}
		}
	},
	created() {
		this.$store.dispatch("surveys/fetchById", this.id);
	},
};
</script>
