<template>
	<v-textarea
        label="Paste Options"
		rows="4"
		v-model="string"
		@blur="stringToArray"
		hint="Paste rows of texts or text|value pairs"
		rounded
		filled
		permanent-hint
	>
	</v-textarea>
</template>
,

<script>
export default {
	name: "PasteOptions",
	props: {
		value: { type: [Array] },
	},
	data: () => {
		return {
			string: "",
		};
	},
	methods: {
		arrayToString() {
			const self = this;
			return self.value.map((i) => `${i.text}|${i.value}\n`).join("");
		},
		stringToArray() {
            const self = this;
			let items = self.string.split("\n");
			items = items.filter((i) => i.length);
			items = items.map((item, i) => {
				let pair = item.split("|");
				let obj = { text: pair[0] };
				if (pair[1]) {
					obj.value = pair[1];
				} else {
					obj.value = self.mwutils.strToKey(obj.text);
				}
				obj.position = i;
				return obj;
			});
			this.$emit("input", items);
		},
	},
	watch: {
		value: {
			immediate: true,
			handler() {
				this.string = this.arrayToString();
			},
		},
	},
};
</script>
