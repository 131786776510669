<template>
    <v-sheet flat>
        <mw-form-field v-for="field in fields"
            @input=" v => update(v, field.value)"
            :values="screener"
            :field="field.value"
            :key="field.value"
            v-bind="model[field.value]"
        ></mw-form-field>
    </v-sheet>
</template>

<script>
import Survey from "@/models/Survey";
import MwFormField from "@c/ui/MwFormField";
export default {
    name: "ScreenerSettings", 
    props: {
        id: {type: String}
    },
    data: () => {
        return {
            fieldIds: ["identify_participant", "thankyou", "url", "redirect_url", "redirect_to_scheduling"],
        }
    }, 
    computed: {
		model() {
			return Survey;
		},
        fields(){
            const self = this;
            return self.fieldIds.map( field => self.model[field] );
        },
        screener(){
            return this.$store.state.surveys.data[this.id]
        }
    },
    components: {
        MwFormField
    }, 
    methods: {
        update(value, key){
            this.$store.dispatch("surveys/patch", {id: this.id, [key]: value })
        }
    }
}
</script>