<template>
	<v-btn rounded :color="color" @click="$emit('click', value)" class="mr-2 mb-2" >
		<span class="text-large font-weight-bold">{{ label || value }}</span>
	</v-btn>
</template>


<style lang="less" scoped>
    .v-btn {
        text-transform: none;
        display: inline-grid;
        white-space: break-spaces;
        height: auto !important;
        padding: 8px !important;
    }
</style>

<script>
export default {
	name: "ConditionButton",
	props: {
		value: { type: String },
		label: { type: String },
		color: { type: String },
	},
};
</script>
