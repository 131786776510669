<template>
	<v-sheet flat color="transparent">
		<mw-edit :id="id" storeModule="surveys" :model="model">
			<template v-slot:append>
				<slot/>
				<track-button :id="id" object="survey"></track-button>
			</template>
		</mw-edit>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Survey from "@/models/Survey";
import TrackButton from "@c/buttons/TrackButton";
import MwEdit from "@c/ui/MwEdit";
export default {
	name: "EditSurveySettings",
	props: {
		id: { type: String, required: false }, // @doc the id string of the object - will add a new document if not passed
	},
	data: () => {
		return {};
	},
	computed: {
		model() {
			return Survey;
		},
	},
	components: {
		MwEdit,
		TrackButton,
	},
	methods: {},
};
</script>
