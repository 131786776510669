<template>
	<v-sheet flat>
		<condition-button
			v-if="!hasSubquestions"
			:label="title"
			:value="templateString"
			:color="isActive ? 'success' : 'primary'"
			@click="$emit('click', { question: id, string: templateString })"
		></condition-button>
		<template v-else>
			<p class="mt-3">{{ title }}:</p>
			<question-condition
				v-for="subquestion in question.subquestions"
				:key="subquestion.value"
				:id="id"
				:subquestion="subquestion.value"
				color="orange"
				@click="v => $emit('click', v )"
			></question-condition>
		</template>
	</v-sheet>
</template>

<script>
import ConditionButton from "@c/survey/builder/conditions/ConditionButton.vue";
export default {
	name: "QuestionCondition",
	props: {
		id: { type: String },
		focus: { type: [Object] },
		subquestion: { type: String },
	},
	components: { ConditionButton },
	computed: {
		question() {
			return this.$store.state.questions.data[this.id];
		},
		hasSubquestions() {
			if (this.subquestion) {
				return false;
			}
			return this.question.subquestions && Object.values(this.question.subquestions).length;
		},
		isNumber() {
			return this.question.input_type == "number";
		},
		title() {
            if( this.subquestion ){
                return this.question.subquestions[this.subquestion].text;
            }
			return this.$store.getters["questions/title"](this.id);
		},
		isActive() {
            if( !this.focus ){
                return false;
            }
			return this.focus.question == this.id;
		},
		templateString() {
			if (!this.subquestion) {
				if (this.isNumber) {
					return `{{${this.id}}}`;
				} else {
					return `'{{${this.id}}}'`;
				}
			} else {
				return `'{{${this.id}.${this.subquestion}}}'`;
			}
		},
	},
	methods: {
		append() {
			console.log(" ");
		},
	},
};
</script>
