<template> 
	<v-sheet flat color="transparent" v-if="id">
		<mw-edit :id="id" storeModule="questions" :model="model">
			<template v-slot:append>
				<v-component :is="component" :id="id"></v-component>
			</template>
		</mw-edit>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Question from "@/models/Question";
import MwEdit from "@c/ui/MwEdit";

export default {
	name: "EditQuestionSettings",
	props: {
		id: { type: String, required: false }, // @doc the id string of the object - will add a new document if not passed
	},
	data: () => {
		return {
		};
	},
	computed: {
		model() {
			return Question;
		},
		question() {
			return this.$store.state.questions.data[this.id];
		},
		component() {
			if (!this.question) {
				return;
			}
			var types = {
				checkboxes: "Checkboxes",
				html: "Html",
				tiles: "Tiles",
				header: "Header",
				input: "Input",
				matrix: "Matrix",
				comment: "Comment",
			};
			return types[this.question.type] || false;
		},
	},
	components: {
		MwEdit,
		Checkboxes: () => import("@/components/survey/builder/questionTypes/Checkboxes"),
		Header: () => import("@/components/survey/builder/questionTypes/Header"),
		Comment: () => import("@/components/survey/builder/questionTypes/Comment"),
		Html: () => import("@/components/survey/builder/questionTypes/Html"),
		Input: () => import("@/components/survey/builder/questionTypes/Input"),
		Matrix: () => import("@/components/survey/builder/questionTypes/Matrix"),
		Tiles: () => import("@/components/survey/builder/questionTypes/Tiles"),
	},
};
</script>
