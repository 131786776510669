<template>
	<v-sheet flat>
		<edit-survey-settings v-if="!id" :id="id"></edit-survey-settings>
		<survey-builder v-else :id="id"></survey-builder>
	</v-sheet>
</template>

<style lang="less"></style>


<script type="text/javascript">
import SurveyBuilder from '@c/survey/builder/SurveyBuilder.vue';
import EditSurveySettings from '@c/survey/builder/EditSurveySettings.vue';


export default {
	name: "EditSurvey",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
	data: () => {
		return {
		};
	},
	components: {
		SurveyBuilder,
		EditSurveySettings
	},
	methods: {
	},
};
</script>
