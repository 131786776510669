<template>
	<v-sheet color="transparent">
		<v-btn block color="primary" @click="insertDemog">Insert Standard Demographics</v-btn>
		<v-text-field label="Search" v-model="search"></v-text-field>
		<v-list dense>
			<v-list-item
				v-for="(question, i) in filteredQuestions"
				:key="i"
				:id="question.id"
			>
				<v-list-item-content>
					<v-list-item-title>
						{{ question.title }}
					</v-list-item-title>
					<v-list-item-subtitle v-if="question.content">
						<span v-html="question.content"></span>
					</v-list-item-subtitle>
				</v-list-item-content>
				<v-list-item-action v-if="context">
					<v-btn icon @click="insert(question.id)"
						><v-icon>mdi-check-circle</v-icon></v-btn
					>
				</v-list-item-action>
			</v-list-item>
		</v-list>
	</v-sheet>
</template>

<style lang="less" scoped>
.v-list-item__title {
	overflow: auto;
	white-space: break-spaces;
}
</style>
<script>
export default {
	name: "QuestionLibrary",
	props: {
		context: { type: String },
	},
	data: () => {
		return {
			search: "",
		};
	},
	computed: {
		questions() {
			return this.$store.getters["questions/libraryQuestions"];
		},
		demographics(){
			return this.$store.getters["questions/standardDemographicsQuestions"];
		},
		filteredQuestions() {
			const self = this;
			return self.questions.filter((question) => {
				return question.title
					.toLowerCase()
					.includes(this.search.toLowerCase());
			});
		},
    },
    methods: {
		insert(id) {
			this.$store.dispatch("surveys/addQuestion", {
				id: this.context,
				question: id,
			});
		},
		insertDemog(){
			const self = this;
			self.demographics.forEach( id => {
				self.insert(id)
			})
		}
	},
	created() {
		this.$store.dispatch("questions/getLibraryQuestions");
	},
};
</script>
